import React, { Component, Fragment } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

import Information from './components/Information'
import Experience from './components/Experience';
import Skills from './components/Skills';
import Formation from './components/Formation';

import './Profile.css';

class Profile extends Component {
  state = {
    activeTab: 0,
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  render() {
    const { activeTab } = this.state;
    const { talent } = this.props;
    // console.log("onfo talent: ", talent);
    return (
      <Fragment>
        <Nav tabs>
          <NavItem  >
            <NavLink
              className={`${classnames({ active: activeTab === 0 })} tabs`}
              onClick={() => this.toggleTab(0)}>
              <i className="fa fa-info"></i>&nbsp;<strong className="titles">Información</strong>
            </NavLink>
          </NavItem>
          <NavItem >
            <NavLink
              className={`${classnames({ active: activeTab === 1 })} tabs`}
              onClick={() => this.toggleTab(1)}>
              <i className="fa fa-signal"></i>&nbsp;<strong>Experiencia</strong>
            </NavLink>
          </NavItem>
          <NavItem >
            <NavLink
              className={`${classnames({ active: activeTab === 2 })} tabs`}
              onClick={() => this.toggleTab(2)}>
              <i className="fas fa-list-ul"></i>&nbsp;<strong>Aptitudes</strong>
            </NavLink>
          </NavItem>
          <NavItem >
            <NavLink
              className={`${classnames({ active: activeTab === 3 })} tabs`}
              onClick={() => this.toggleTab(3)}>
              <i className="fa fa-university"></i>&nbsp;<strong>Formación</strong>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} className="tabs-info-talent" >
          <TabPane tabId={0}>
            <Information
              talent={talent} />
          </TabPane>

          <TabPane tabId={1}>
            <Experience
              historical={talent.historicalExperience.list} />
          </TabPane>

          <TabPane tabId={2}>
            <Skills
              skills={talent.skillTalents.edges} />
          </TabPane>

          <TabPane tabId={3}>
            <Formation
              academicTrainings={talent.skillTalents.edges} />
          </TabPane>
        </TabContent>
      </Fragment>
    )
  }
}

export default Profile;
