const defaultImagesGender = (gender) => {
  let defaultImage = '';
  switch (gender) {
    case 'masculino': defaultImage = require('../assets/img/default/defaultMan.png'); break;
    case 'femenino': defaultImage = require('../assets/img/default/defaultWoman.png'); break;
    case 'otro': defaultImage = require('../assets/img/default/defaultWoman.png'); break;

    default: defaultImage = ''; break;
  }

  return defaultImage;
}

const configureNameProfileByGender = (gender, profile) => {
  let newProfile = '';
  switch (profile) {
    case 'musico': newProfile = 'Musico'; break;
    case 'cantante': newProfile = 'Cantante'; break;
    case 'modelo': newProfile = 'Modelo'; break;
    case 'presentador': newProfile = gender === 'masculino' || 'otro' ? 'Presentador' : 'Presentadora'; break;
    case 'actor': newProfile = gender === 'masculino' || 'otro' ? 'Actor' : 'Actriz'; break;
  
    default: newProfile = ''; break;
  }

  return newProfile
}

export { 
  defaultImagesGender, 
  configureNameProfileByGender
}
