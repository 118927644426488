import React, { Fragment } from 'react';
import { Button, Col, Label, Row } from 'reactstrap'
import Profile from './components/Profile/Profile';
import Gallery from './components/Gallery';
import IBook from './components/IBook';
import { configureNameProfileByGender } from '../../utils/default.js';
import './TalentView.css';
import defaultMan from '../../assets/img/default/defaultMan.png';
import defaultWoman from '../../assets/img/default/defaultWoman.png';

import { createPortal } from 'react-dom'
import ModalDeletePhoto from './components/Modals/ModalDeletePhoto/ModalDeletePhoto.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);


const TalentView = props => {

  const { returnEvent, talent, validEventClient, eventId, adminTalent, showMultimedia,
    openModal, handleOpenModalDelete, handleCloseModal } = props;

  const gender = "F";
  const isExistSocialNetwork = true;
  const profiles = talent.profiles.list.filter(profile => profile.main);
  let photoDefault;

  if (talent.gender === 'MASCULINO') {
    photoDefault = defaultMan;
  } else {
    photoDefault = defaultWoman;
  }

  const handleSocialNetwork = (typeSocialNetwork, value, dominio) => {
    if (value) {
      return (
        <div className="mt-2 data-secundary">
          <a title={value} href={`${dominio}${value}`} target="_blank" rel="noopener noreferrer">
            {
              typeSocialNetwork !== "tiktok" ?
                <i className={`fab fa-${typeSocialNetwork}`}></i>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tiktok" viewBox="0 0 16 16">
                  <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                </svg>
            }
            &nbsp;{value}
          </a>
        </div>
      )
    } else {
      return null
    }
  }

  const handleAprobar = () => {
    const { talent, setFieldValue } = props;
    MySwal.fire({
      title: "¿Desea aprobar la imagen?",
      icon: 'question',
      iconColor: '#fece0c',
      showCancelButton: true,
      confirmButtonText: `Aceptar`,
      confirmButtonColor: '#4dbd74',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '##cdcdcd',
    }).then((result) => {
      if (result.isConfirmed) {
        setFieldValue('talent', {
          ...talent, statePhoto: 82, observation: 'Ninguna',
        });
      }
    })
  };

  const handleRechazar = (typeReyect, index) => {
    const { talent, setFieldValue } = props;

    setFieldValue('talent', {
      ...talent, statePhoto: 83, observation: typeReyect.toString(),
    });

    handleCloseModal();
  }

  const generateImages = () => {
    let imagesGallery = [], imagesIbook = [];
    if (talent.images.list.length !== 0) {
      talent.images.list.forEach(image => {
        const type = !image.type ? image.type : image.type.toLowerCase();
        if (image.stateId == 82 || adminTalent) {
          if (type === null || type.includes('ll')) {
            if (image.route !== null) {
              imagesGallery.push(image)
            }
          } if (type.includes('k')) {
            if (image.route !== null) {
              imagesIbook.push(image);
            }
          }
        }
      })
    }
    return { imagesGallery, imagesIbook }
  }

  const { imagesGallery, imagesIbook } = generateImages();
  const borderStyle = {
    border: talent.statePhoto === 81 ? '3px solid #eec000' :
      talent.statePhoto === 82 ? '3px solid #27DA33' :
        talent.statePhoto === 83 ? '3px solid red' :
          talent.statePhoto === 84 ? '3px solid gray' : 'none'
  };
  return (
    <Fragment>
      <div className="animated fadeIn mb-3" id="view-talent">
        {
          eventId == null || validEventClient ?
            <Row>
              <Col xs={1} className="arrows-talent" >
                {/* <div className="d-flex align-items-center container-before">
                    <Button className="before" title="Anterior">
                      <i className="fas fa-angle-left fa-3x"></i>
                    </Button>
                </div> */}
              </Col>

              <Col xs={10} className="mt-4">
                <Row className="container-info">
                  <Col md={3}>
                    <Row className="text-center">
                      <Col sm={12} md={12}>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                          <Col md={12}>
                            <img
                              style={{ ...(props.adminTalent && borderStyle), width: '9rem', height: '9rem' }}
                              className="photo"
                              title={`${talent.name} ${talent.lastname}`}
                              alt={`${talent.name} ${talent.lastname}`}
                              src={
                                talent.photo
                                  ? talent.photo.startsWith('http')
                                    ? talent.photo
                                    : `${process.env.REACT_APP_URL_IMAGEN}${process.env.REACT_APP_URL_STORAGE_IMAGEN}${talent.photo}`
                                  : photoDefault
                              } />
                          </Col>
                          {
                            (props.adminTalent) &&
                            <div style={{ display: 'flex', justifyContent: 'space-around', width: '60%' }}>
                              <Button
                                style={{ width: '100%', borderRadius: '10px 10px', cursor: talent.statePhoto === 82 ? 'not-allowed' : 'pointer' }}
                                disabled={talent.statePhoto === 82 || talent.statePhoto == 84}
                                size='sm'
                                title='Aprobado'
                                color='success'
                                onClick={() => handleAprobar()}
                              >
                                <i className="fa fa-check" aria-hidden="true"></i>
                              </Button>

                              <Button
                                style={{ width: '100%', borderRadius: '10px 10px', cursor: talent.statePhoto === 83 ? 'not-allowed' : 'pointer' }}
                                disabled={talent.statePhoto === 83 || talent.statePhoto == 84}
                                size='sm'
                                title='Rechazado'
                                color='warning'
                                onClick={() => handleOpenModalDelete()}
                              >
                                <i className="fa fa-times" aria-hidden="true"></i>
                              </Button>
                            </div>
                          }
                        </Row>
                      </Col>

                      <Col sm={12}>
                        <Row>
                          <Col md={12}>
                            <Label className="name-talent mt-2"><strong>{talent.name} {talent.lastname}</strong></Label>
                          </Col>

                          <Col sm={12} md={12} className="list-tags mt-2">
                            {profiles.map(({ profile }, index) => {
                              let newProfile = configureNameProfileByGender(gender, profile.toLowerCase())
                              return (
                                <span key={index}>
                                  <i className="fas fa-circle"></i>{` ${newProfile}`}
                                </span>
                              )
                            })}
                          </Col>

                          {isExistSocialNetwork &&
                            <div className="social-networks">
                              <Label className="mt-3"><strong>Redes Sociales</strong></Label>

                              <Fragment>
                                {handleSocialNetwork('twitter', talent.twitter, 'https://x.com/')}
                                {handleSocialNetwork('facebook', talent.facebook, 'https://www.facebook.com/')}
                                {handleSocialNetwork('instagram', talent.instagram, 'https://www.instagram.com/')}
                                {handleSocialNetwork('snapchat', talent.snapchat, 'https://www.snapchat.com/add/')}
                                {handleSocialNetwork('youtube', talent.youtube, 'https://www.youtube.com/@')}
                                {handleSocialNetwork('spotify', talent.spotify, '')}
                                {handleSocialNetwork('sellsy', talent.deezer, 'https://www.deezer.com/')}
                                {handleSocialNetwork('tiktok', talent.tiktok, 'https://www.tiktok.com/@')}
                              </Fragment>
                            </div>
                          }
                          {
                            (!validEventClient && !adminTalent) &&
                            <Col sm={12} md={12} className="mt-4" >
                              <Button color="warning" title="Volver al evento" onClick={returnEvent}>
                                <i className="far fa-arrow-alt-circle-left"></i>
                                &nbsp;Volver al evento
                              </Button>
                            </Col>
                          }
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={9}>
                    <Profile talent={talent} />

                    {
                      (typeof showMultimedia === 'undefined') &&
                      <Fragment>
                        <Label className="mt-4">
                          <span className="container-camera p-2">
                            <i className="fa fa-camera camera" ></i>
                          </span>
                          &nbsp;<strong>Book de Regristro</strong>
                        </Label>

                        <div className="container-images mt-2 mb-4">
                          <IBook
                            adminTalent={adminTalent}
                            images={imagesIbook} />
                        </div>
                      </Fragment>
                    }
                    {
                      (typeof showMultimedia === 'undefined') && // Cambiado '&&' a '||'
                      <Fragment>
                        <Label className="mt-4">
                          <span className="container-camera p-2">
                            <i className="fa fa-camera camera" ></i>
                          </span>
                          &nbsp;<strong>Galería de Eventos</strong>
                        </Label>

                        <div className="container-images mt-2 my-3">
                          <Gallery
                            adminTalent={adminTalent}
                            images={imagesGallery} />
                        </div>
                      </Fragment>
                    }
                  </Col>
                </Row>
              </Col>

              <Col className="arrows-talent" xs={1} >
                {/* <div className="d-flex align-items-center container-after">
                      <Button className="after" title="Siguiente">
                        <i className="fas fa-angle-right fa-3x"></i>
                      </Button>
                </div> */}
              </Col>

              {
                openModal && createPortal(
                  <ModalDeletePhoto
                    handleCloseModal={handleCloseModal}
                    isOpen={openModal}
                    handleRechazar={handleRechazar}
                  />, document.getElementById('modal')
                )
              }
            </Row>
            :
            <Row>
              <Col>
                <h1>Su evento está caducado.</h1>
              </Col>
            </Row>
        }
      </div>
    </Fragment>
  );
};

export default TalentView;