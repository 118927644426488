import React, { Component, Fragment, createRef } from 'react';
import { compose, graphql, withApollo } from 'react-apollo';
import ContractView from './ContractView';
import { GET_PARAM_CONTRACT, GET_TALENT_CONTRACT, GET_DATA_AGENCY_CONTRACT, GET_ID_CONVOCATED } from '../../../controllers/graphql/queries';
import { SAVE_URL_CONTRACT } from '../../../controllers/graphql/mutation'
import ServiceInteractor from '../../../controllers/services/ServiceInteractor';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import jsPDF from 'jspdf';

const MySwal = withReactContent(Swal);
const Interactor = new ServiceInteractor();

class Contract extends Component {

    constructor(props) {
        super(props);
        this.printRef = createRef();
    }

    state = {
        initialValues: {},
        loading: true,
        eventValid: false,
        paramContract: {},
        habeasData: {},
        dataEvent: {},
        documentation: {},
        message: 'Oops! Contrato no disponible para este evento.',
        idConvocated: null,
    }

    componentWillMount = async () => {        
        try {
            const { match, client } = this.props;

            const convocatedDb = await client.query({
                query: GET_ID_CONVOCATED,
                variables: {
                    id: match.params.idEvent,
                    talentId: match.params.idTalent
                },
                fetchPolicy: 'network-only'
            });

            if (convocatedDb?.data?.convocateds?.nodes[0]?.id) {
                const idConvocated = convocatedDb.data.convocateds.nodes[0].id;

                const reponseEvent = await client.query({
                    query: GET_DATA_AGENCY_CONTRACT,
                    variables: { id: match.params.idEvent },
                    fetchPolicy: 'network-only'
                });

                // console.log("reponseEvent: ", reponseEvent);
                
                let dataEvent = {
                    nameEvent: reponseEvent.data.convocatory.event.name,
                    code: reponseEvent.data.convocatory.code,
                    state: reponseEvent.data.convocatory.event.stateId,
                    agency: {
                        name: reponseEvent.data.convocatory.event.quotation.agency.name || '',
                        adress: reponseEvent.data.convocatory.event.quotation.agency.address || reponseEvent.data.convocatory.event.quotation.agency.users.nodes[0].address,
                        nit: reponseEvent.data.convocatory.event.quotation.agency.identification || '',
                        city: reponseEvent.data.convocatory.event.quotation.agency.city.name || '',
                        country: reponseEvent.data.convocatory.event.quotation.agency.city.country.name || '',
                    },
                    producer: {
                        name: reponseEvent.data.convocatory.event.quotation.agency.legalRepresentatives.nodes[0].name || `${reponseEvent.data.convocatory.event.quotation.agency.users.nodes[0].name || ''} ${reponseEvent.data.convocatory.event.quotation.agency.users.nodes[0].lastname || ''}`,
                        email: reponseEvent.data.convocatory.event.quotation.agency.users.nodes[0].email || '',
                        identification: reponseEvent.data.convocatory.event.quotation.agency.legalRepresentatives.nodes[0].document || reponseEvent.data.convocatory.event.quotation.agency.users.nodes[0].identification,
                        city: reponseEvent.data.convocatory.event.quotation.agency.legalRepresentatives.nodes[0].city.name || reponseEvent.data.convocatory.event.quotation.agency.users.nodes[0].city.name
                    }
                }

                // no lo deja firmar contrato, ya que debe ser un evento abierto
                if (dataEvent.state == 36 || dataEvent.state == 37 || dataEvent.state == 37) {
                    this.setState({
                        eventValid: false,
                        loading: false,
                        message: 'Evento caducado.'
                    })
                } else {
                    const response = await client.query({
                        query: GET_TALENT_CONTRACT,
                        variables: { id: match.params.idTalent },
                        fetchPolicy: 'network-only'
                    });

                    const talento = response.data.talent

                    let initialValues = {
                        typeContract: '',
                        dateContract: this.formatDate(),

                        habeasData: {
                            habeasData: {
                                label: 'Acepto la Política de Habeas Data *',
                                value: false,
                            },
                        },
                        clausula: {
                            clausula: {
                                label: 'Acepto Cláusulas *',
                                value: false,
                            },
                        },

                        name: `${talento.name || ''} ${talento.lastname || ''}`,
                        identification: talento.identification || '111',
                        phone: talento.phone || '',
                        address: talento.address || '',
                        email: talento.email || '',

                        // valores documentos talento: 
                        docId: null,
                        rut: null,
                        eps: null,
                        arl: null,
                    }

                    const { data } = await client.query({ query: GET_PARAM_CONTRACT });
                    const habeasData = data.contracts.nodes.find(item => item.clause === 'Habeas Data');
                    let paramContract = data.contracts.nodes.filter(item => (item.clause !== "Habeas Data" && item.clause !== 'Claúsula Primera'));
                    let clausulaToEdit = data.contracts.nodes.filter(item => (item.clause == 'Claúsula Primera'));

                    clausulaToEdit[0].description = clausulaToEdit[0].description
                        .replace(/producerName/g, `<strong>${dataEvent.producer.name}</strong>`)
                        .replace(/producerIdentification/g, `<strong>${dataEvent.producer.identification}</strong>`)
                        .replace(/producerCity/g, `<strong>${dataEvent.producer.city}</strong>`)
                        .replace(/agencyName/g, `<strong>${dataEvent.agency.name}</strong>`)
                        .replace(/agencyCountry/g, `<strong>${dataEvent.agency.country}</strong>`)
                        .replace(/agencyCity/g, `<strong>${dataEvent.agency.city}</strong>`)
                        .replace(/agencyNit/g, `<strong>${dataEvent.agency.nit}</strong>`);

                    paramContract.push(clausulaToEdit[0]);
                    let infoContrato = paramContract;
                    // infoContrato.push(habeasData);
                    initialValues.infoContrato = infoContrato;

                    const documentation = [
                        { name: 'docId', label: 'Documento de Identidad *' },
                        { name: 'rut', label: 'RUT *' },
                        { name: 'eps', label: 'EPS *' },
                        { name: 'arl', label: 'ARL *' }
                    ];

                    this.setState({
                        initialValues,
                        paramContract,
                        habeasData,
                        eventValid: true,
                        loading: false,
                        dataEvent,
                        documentation,
                        idConvocated
                    })
                }
                // console.log("dataEvent: ", dataEvent);
                
            } else {
                this.setState({
                    eventValid: false,
                    message: 'Contrato del evento no disponible para este talento.',
                    loading: false,
                })
            }

        } catch (error) {
            // console.log("hay error: ", error);
            
            this.setState({
                eventValid: false,
                loading: false
            })
        }
    }

    formatDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
        const year = today.getFullYear();
        return `${year}-${month}-${day}`;
    };

    submit = async (values) => {

        try {
            const { match, updateUrl } = this.props;
            const { dataEvent, idConvocated, habeasData } = this.state

            const infoContrato = {
                habeasData,
                clausulas: values.infoContrato,
                date: values.dateContract,
                typeContract: values.typeContract == "eventos" ? 'Sin derechos de uso de imagen' : 'Con derechos de uso de imagen',
                nota: values.typeContract == "eventos" ? 'Asistirá a un evento corporativo para apoyar las labores requeridas durante el desarrollo del mismo.' : 'Asistirá a un evento o sesión fotos o videos para la creación de contenido para página web, redes sociales o campañas para medios internos o externos.',
                talent: {
                    name: values.name,
                    document: values.identification,
                    cel: values.phone,
                    adress: values.address,
                    email: values.email,
                }
            }

            const pdfContract = await this.generatePdf(infoContrato);
            // return
            const saveContractAgency = {
                pdfContract,
                url: `${process.env.REACT_APP_API_ENDPOINT}`,
                // url: `https://webapi.niriun.com`,
                talent: {
                    name: values.name,
                    email: values.email,
                },
                agency: {
                    name: dataEvent.agency.name,
                    producerEmail: dataEvent.producer.email,
                },
                event: {
                    code: dataEvent.code,
                    name: dataEvent.nameEvent
                }
            };

            const response = await Interactor.saveContract(saveContractAgency);
            // console.log("response: ", response);


            if (response?.status == 200) {
                // si es exitoso guardamos los documetos del talenyo y notificamos a la agencia                             
                const variables = {
                    id: idConvocated,
                    url: response.path
                }

                const result = await updateUrl({ variables });
                // console.log("result: ", result);


                if (result?.data?.updateConvocated) {

                    const saveDocumentTalent = {
                        contract: response.fileDir,
                        date: values.dateContract,
                        talent: {
                            name: values.name,
                            email: values.email,
                        },
                        agency: {
                            name: dataEvent.agency.name,
                            producerEmail: dataEvent.producer.email,
                            producerName: dataEvent.producer.name,
                        },
                        event: {
                            code: dataEvent.code,
                            name: dataEvent.nameEvent,
                        },
                        documents: [values.arl, values.eps, values.rut, values.docId]
                    };

                    // console.log("saveDocumentTalent: ", saveDocumentTalent);
                    const saveDocument = await Interactor.saveDocumentTalent(saveDocumentTalent, match.params.idTalent);
                    // console.log("saveDocument: ", saveDocument);


                    MySwal.fire({
                        title: `Contrato firmado con exito`,
                        // html: ``,
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: `Aceptar`
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = 'https://niriun.com/';
                        }
                    })
                }

            } else {
                MySwal.fire({
                    title: `${response.response.data.message}`,
                    html: `${response.response.data.error}`,
                    icon: 'error'
                })
            }

        } catch (error) {
            MySwal.fire({
                title: 'Error desconocido',
                html: error,
                icon: 'error'
            })
        }
    }

    generatePdf = async (infoContrato) => {
        return new Promise(async (resolve, reject) => {
            const doc = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
                compress: true
            });

            const maxWidth = 180;
            const pageHeight = doc.internal.pageSize.height;
            const marginTop = 10;
            const marginBottom = 10;

            // Título del documento
            doc.setFontSize(16);
            //texto, posicion x, posiicion y, opciones, rotacion, alineacion 
            doc.text("Contrato Online de Prestación de Servicios para Personal", 105, 15, null, null, 'center');

            // Datos del Empleador
            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            doc.text('Tipo de contrato: ', 10, 30);
            doc.setFont('helvetica', 'normal');
            doc.text(`${infoContrato.typeContract}`, 46, 30);

            doc.setFont('helvetica', 'bold');
            doc.text(`Fecha del contrato:`, 10, 40);
            doc.setFont('helvetica', 'normal');
            doc.text(`${infoContrato.date}`, 50, 40);

            doc.setFont('helvetica', 'bold');
            doc.text("NOTA: ", 10, 50);
            doc.setFont('helvetica', 'normal');
            const splitText = doc.splitTextToSize(infoContrato.nota, maxWidth);
            doc.text(splitText, 25, 50);

            // habeeas data
            doc.setFont('helvetica', 'bold');
            doc.text(`${infoContrato.habeasData.clause}:`, 10, 70);
            doc.setFont('helvetica', 'normal');
            const splithabeas = doc.splitTextToSize(infoContrato.habeasData.description, maxWidth);
            doc.text(splithabeas, 10, 80);

            //clausulas:
            const orderClause = infoContrato.clausulas.sort((a, b) => a.order - b.order);
            let currentY = 140;

            const removeHtmlTags = (text) => {
                return text.replace(/<\/?[^>]+(>|$)/g, "");
            };

            orderClause.forEach((info) => {
                const splitDescription = doc.splitTextToSize(removeHtmlTags(info.description), maxWidth);
                const descriptionHeight = splitDescription.length * 5;
                const titleHeight = 10;
                const totalHeight = titleHeight + descriptionHeight + 10; // 20 es el espacio adicional entre cláusulas
                const remainingSpace = pageHeight - currentY - marginBottom;

                doc.setFont('helvetica', 'bold');
                doc.text(info.clause, 10, currentY);
                currentY += titleHeight;

                doc.setFont('helvetica', 'normal');
                doc.text(splitDescription, 10, currentY);
                currentY += descriptionHeight;

                currentY += 10;

                if (currentY > 250) {
                    doc.addPage();
                    currentY = marginTop;
                }
            });

            // console.log(`posicion y final: ${currentY}`);
            // datos del contratista:
            doc.setFont('helvetica', 'bold');
            doc.text(`Cláusula sexta:`, 10, currentY);
            doc.setFont('helvetica', 'normal');
            doc.text(`Datos del contratista.`, 42, currentY);
            currentY += 10
            doc.setFont('helvetica', 'bold');
            doc.text(`Nombre Completo:`, 10, currentY);
            doc.setFont('helvetica', 'normal');
            doc.text(`${infoContrato.talent.name}`, 49, currentY);
            currentY += 10
            doc.setFont('helvetica', 'bold');
            doc.text(`Cédula:`, 10, currentY);
            doc.setFont('helvetica', 'normal');
            doc.text(`${infoContrato.talent.document}`, 26, currentY);
            currentY += 10
            doc.setFont('helvetica', 'bold');
            doc.text(`Número de Celular:`, 10, currentY);
            doc.setFont('helvetica', 'normal');
            doc.text(`${infoContrato.talent.cel}`, 50, currentY);
            currentY += 10
            doc.setFont('helvetica', 'bold');
            doc.text(`Dirección:`, 10, currentY);
            doc.setFont('helvetica', 'normal');
            doc.text(`${infoContrato.talent.adress}`, 31, currentY);
            currentY += 10
            doc.setFont('helvetica', 'bold');
            doc.text(`Email:`, 10, currentY);
            doc.setFont('helvetica', 'normal');
            doc.text(`${infoContrato.talent.email}`, 23, currentY);
            currentY += 10

            // Calcular la posición X para centrar el texto
            const pageWidth = doc.internal.pageSize.width;
            const text = 'Documento generado por la tecnología NIRIUN';
            const textWidth = doc.getTextWidth(text);
            const xPos = (pageWidth - textWidth) / 2;

            // Colocar el texto centrado en el fondo de la página
            currentY = doc.internal.pageSize.height - 10;
            doc.text(text, xPos, currentY);

            // Generar el PDF
            const pdfBase64 = doc.output('datauristring');
            // doc.save("contrato_de_contratacion.pdf");
            resolve(pdfBase64)
        });
    }


    render() {
        const { loading, eventValid, paramContract, initialValues, habeasData, documentation, message } = this.state;
        if (loading) return 'Cargando..';

        return (
            <Fragment>
                <ContractView
                    message={message}
                    ref={this.printRef}
                    documentation={documentation}
                    habeasData={habeasData}
                    initialValues={initialValues}
                    eventValid={eventValid}
                    loading={loading}
                    paramContract={paramContract}
                    submit={this.submit}
                />
            </Fragment>
        )
    }
}

export default compose(
    withApollo,
    graphql(SAVE_URL_CONTRACT, { name: 'updateUrl' }),
)(Contract);